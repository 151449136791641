@import '../../../../scss/theme-bootstrap';

.gnav-util--wishlist {
  .gnav-util__icon--wishlist {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
  .gnav-util__wishlist-confirm {
    &-content {
      display: none;
    }
    &-no-items {
      display: none;
      .gnav-util__wishlist-confirm-buttons {
        padding-top: 32px;
      }
    }
    &-empty {
      font-family: $font--lars;
      font-size: 14px;
      line-height: get-line-height(14px, 14px);
    }
    &-buttons {
      a.gnav-util__wishlist-confirm-button-wishlist {
        width: 100%;
        color: $color-cream;
        &:hover {
          color: $color-cream;
        }
      }
    }
    &-products {
      overflow-y: auto;
      max-height: 300px;
      padding: 0 44px;
      line-height: get-line-height(12px, 18px);
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: $color-black;
      }
    }
  }
  &.wishlist-block--nonempty {
    .gnav-util__wishlist-confirm-content {
      display: block;
    }
    &.wishlist-loading {
      .gnav-util__wishlist-confirm-content {
        display: none;
      }
    }
  }
  &.wishlist-block--empty {
    .gnav-util__wishlist-confirm-no-items {
      display: block;
    }
    &.wishlist-loading {
      .gnav-util__wishlist-confirm-no-items {
        display: none;
      }
    }
    .gnav-util__icon-wishlist-count {
      display: none;
    }
  }
  &.wishlist-loading {
    .gnav-util__wishlist-confirm-loading {
      display: block;
      opacity: 1;
    }
  }
}
